import React, { useState } from 'react';
import $ from 'jquery';

function App() {
  const [prediction, setPrediction] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.target;
      const formData = $(form).serializeArray();
      const data = {};
      const newErrors = {};
      
      for (let i = 0; i < formData.length; i++) {
          const value = formData[i].value.trim();
          data[formData[i].name] = value;
          if (!value) {
              newErrors[formData[i].name] = true;
          }
      }

      if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
      }

        $.ajax({
            type: "POST",
            url: "https://python-app-hjain47tia-ew.a.run.app/predict", // Adjust the URL accordingly
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
            success: function(response) {
              setPrediction(response.prediction);
            },
            error: function(err) {
                console.log(err);
            }
        });
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full p-8  bg-white shadow-lg rounded-lg">
                <h1 className="text-2xl font-bold mb-6 text-center">Let's make Pharma Future with AI</h1>
                <form id="predict-form" onSubmit={handleSubmit}>
                    <label htmlFor="B">Diameter (e.g. 76)</label><br />
                    <input type="text" id="B" name="B" className="border border-gray-300 rounded-md p-2 mb-2 w-full" /><br />
                    <label htmlFor="E">Weight of lipid (g) (e.g. 0.01116)</label><br />
                    <input type="text" id="E" name="E" className="border border-gray-300 rounded-md p-2 mb-2 w-full" /><br />
                    <label htmlFor="H">No. of nt in DS (e.g. 4283)</label><br />
                    <input type="text" id="H" name="H" className="border border-gray-300 rounded-md p-2 mb-2 w-full" /><br />
                    <label htmlFor="I">DS weight (e.g. 0.00047752)</label><br />
                    <input type="text" id="I" name="I" className="border border-gray-300 rounded-md p-2 mb-2 w-full" /><br />
                    <label htmlFor="L">No. of  RNA copy per NP (e.g. 4.21)</label><br />
                    <input type="text" id="L" name="L" className="border border-gray-300 rounded-md p-2 mb-2 w-full" /><br />
                    {errors.L && <p className="text-red-500">Please enter a value</p>}
                    <input type="submit" value="Submit" className="block mx-auto bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 cursor-pointer" />
                </form>
                <div className="mt-4"> Result:
                    {prediction !== null && (
                        <p>Predicted yield: {prediction}</p>
                    )}
                </div>

            </div>
        </div>
    );
}

export default App;
